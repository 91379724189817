frappe.provide("mrxaudit");
frappe.provide("mrxaudit.utils");

$.extend(mrxaudit, {});

$.extend(mrxaudit.utils, {
  getReportee: async () => {
    let user = await frappe.db.get_doc("User", frappe.session.user);
    if (!user?.mrxuid) {
      return [];
    }
    manager = await frappe.db.get_doc("MrxUser", user.mrxuid);

    users = await frappe.db.get_list("MrxUser", {
      fields: ["name", "documentid", "isactive", "mrname", "managerid"],
      limit: 200,
    });

    if (
      manager.designation === "Country Head" ||
      manager.designation === "Admin" ||
      manager.designation === "IT" ||
      manager.designation === "Audit"
    ) {
      return users.filter((doc) => doc.isactive);
    } else if (manager.designation === "Area Sales Manager") {
      var directReportee = users.filter(
        (doc) => doc.managerid === manager.documentid && doc.isactive
      );
      var indirectReportee = [];

      for (var index = 0; index < directReportee.length; index++) {
        let element = directReportee[index];
        var tmprep = [];
        if (element.ismanager) {
          tmprep = users.filter(
            (doc) => doc.managerid === element.documentid && doc.isactive
          );
          if (tmprep.length) {
            indirectReportee.push(...tmprep);
          }
        }
      }

      return [manager, ...directReportee, ...indirectReportee];
    }
    return users.filter(
      (doc) => doc.managerid === manager.documentid && doc.isactive
    );
  },
});
